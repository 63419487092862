import StatusBox from "@src/common/components/status-box";
import {statusData} from "@src/components/dashboard/orders/staticData";
import React, {useState} from "react";
import {connect} from "react-redux";
import {actions as createOrderActions} from "@sagas/orders/create";

const Header = ({order, loading, changeOrdersStatus}) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<div className="order__form__modal-header">
			<StatusBox
				statusData={statusData}
				editable
				orderIdsAndClientTypes={{
					id: order?.orderId,
					clientPayoutModel: order?.clientPayoutModel,
				}}
				changeOrdersStatus={changeOrdersStatus}
				loading={loading}
				row={order?.status}
			/>
		</div>
	);
};
const mapStateToProps = (state) => ({
	loading: state.app.orders.create.loading,
	order: state.app.orders.edit.order,
});
const mapDispatchToProps = {
	changeOrdersStatus: createOrderActions.changeOrdersStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
