/* eslint-disable no-nested-ternary */
import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as orderActions} from "@sagas/orders";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {actions as energyActions} from "@sagas/energy/index";
import {actions as userOrderActions} from "@sagas/orders/userOrders";
import {
	ToastErrorComponent,
	ToastInfoStatus,
	ToastComponent,
	ToastSuccesComponent,
} from "@common/ToastComponent/ToastComponent";
import {getEndpointBasedOnVersion} from "@src/utils/getEndpointBasedOnVersion";
import i18n from "../../../../i18n";
const logger = new Logger("Orders create");

const PREFIX = "@app/orders/create";
export const ADD_ORDER = `${PREFIX}ADD_ORDER`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_ORDER_SUCCESS = `${PREFIX}ADD_ORDER_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_ORDER = `${PREFIX}FILL_ORDER`;
export const SELECTED_ORDERS = `${PREFIX}SELECTED_ORDERS`;
export const CHANGE_ORDER_STATUS = `${PREFIX}CHANGE_ORDER_STATUS`;
export const CREATE_ORDER = `${PREFIX}CREATE_ORDER`;

const _state = {
	loading: false,
	orderAdded: false,
	orderInitialValues: {
		location: "",
		productId: 0,
		tariffId: 0,
		projectCategoryId: 0,
		clientId: 0,
	},
	showModal: false,
	selectedOrders: [],
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_ORDER_SUCCESS:
				draft.orderAdded = action.payload;
				break;
			case FILL_ORDER:
				draft.orderInitialValues = action.payload;
				break;
			case SELECTED_ORDERS:
				draft.selectedOrders = action.payload;
				break;

			case SHOW_MODAL:
				draft.showModal = action.payload;
				if (!action.payload) {
					draft.orderInitialValues = {
						location: "",
						productId: 0,
						tariffId: 0,
						provision: 0,
						clientId: 0,
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addOrder: (payload) => createAction(ADD_ORDER, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addOrderSucces: (payload) => createAction(ADD_ORDER_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillOrder: (payload) => createAction(FILL_ORDER, {payload}),
	setSelectedOrders: (payload) => createAction(SELECTED_ORDERS, {payload}),
	changeOrdersStatus: (payload) => createAction(CHANGE_ORDER_STATUS, {payload}),
	createOrder: (payload) => createAction(CREATE_ORDER, {payload}),
};

export const sagas = {
	*addOrder({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {data, handleClose} = payload;
			yield axios.post(`/v1/energy/order/create`, data);
			yield put(energyActions.clearEnergyTariffs());
			handleClose();
			ToastSuccesComponent(i18n.t("OrderCreatedSuccess"));
		} catch (error) {
			ToastErrorComponent(i18n.t("OrderCreatedError"));
			return error;
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*changeOrdersStatus({payload}) {
		yield put(actions.setLoading(true));
		const {setEditModal, setSelectedStatus} = payload;
		try {
			if (payload?.ordersData?.length === 0) {
				ToastErrorComponent(i18n.t("NoDataDownload"));
				yield put(actions.setLoading(false));
				yield put(actions.setShowModal(false));
				return;
			}

			const {endpoint} = getEndpointBasedOnVersion("Change_order_status_v2", "/order/status");
			const obj = [];
			// eslint-disable-next-line no-unused-expressions
			payload?.orders?.map((e) => obj.push(e));
			const response = yield axios.put(endpoint, {
				orderIdsAndClientTypes: obj,
				status: payload?.statusValue?.includes("CANCELLED")
					? "CANCELLED"
					: payload?.statusValue === "CLARIFICATION"
					? "APPROVED"
					: payload?.statusValue === "BILLING_NEXT_MONTH"
					? "APPROVED"
					: payload.statusValue === "CONTRACT_REACTIVATED"
					? "SETTLED"
					: payload?.statusValue,
				statusName: payload?.statusName,
				changeStatusType: payload?.changeStatusType && payload?.changeStatusType,
				billingMonthId: payload?.billingMonthId || null,
				description: payload?.description || "",
				baugoId: payload?.baugoId || null,
				settledUuid: payload?.settledUuid || null,
			});
			if (payload?.clientId) {
				yield put(userOrderActions.getSingleUserOrders(payload?.clientId));
			}
			yield put(userOrderActions.emptyPaidCases());
			yield put(orderActions.changeOrderStatusSuccess(payload));
			if (response?.data?.status === 200) {
				if (response?.data?.data === "PROCESSING") {
					ToastComponent(
						`${i18n.t("StatusChangePending")}`,
						"warning",
						orderActions,
						"statusHistory",
					);
				} else {
					yield put(orderActions.setOrdersStatusInProgress(obj));
					yield put(editOrderActions.setOrderStatusInProgress(obj));
					ToastInfoStatus(`${i18n.t("StatusChangeSuccess")}`, orderActions);
				}
			}
			yield put(actions.setShowModal(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
			yield put(actions.setShowModal(false));
			if (setEditModal) {
				setSelectedStatus("");
				setEditModal(false);
			}
		}
	},
	*createOrder({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {data, handleClose} = payload;
			yield axios.post(`/order/create`, data);
			handleClose();
			ToastSuccesComponent(i18n.t("OrderCreatedSuccess"));
		} catch (error) {
			ToastErrorComponent(i18n.t("OrderCreatedError"));
			return error;
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_ORDER, sagas.addOrder);
	yield takeLatest(CHANGE_ORDER_STATUS, sagas.changeOrdersStatus);
	yield takeLatest(CREATE_ORDER, sagas.createOrder);
};
