/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
import React, {useState} from "react";
import {Title} from "@components/dashboard/orders/createEditOrder/title";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {actions as clientActions} from "@sagas/client";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import HtmlTooltip from "@src/common/htmlTooltip";
import Loader from "@src/common/Loader";
import PreviewFile from "@src/common/messagesComponents/PreviewFileComponent";
import MediaBox from "@src/common/messagesComponents/MediaComponent/MediaBox";
import {handleDownloadNoToken} from "@src/utils/download-file-notoken";
import moment from "moment";
import ActivityBox from "@src/common/ActivityBox";
import {EmailIcon} from "@src/assets/SvgComponents";
import {generateFilesArray, isCustomFieldChecked} from "./helper";

const PreviewOrder = ({order, fieldMapping, sections, loading}) => {
	const {t} = useTranslation();
	const currentLanguage = localStorage.getItem("language");
	const sectionName = currentLanguage === "en" ? "nameEn" : "nameDe";
	const sortedSectionsWithShowField = [...sections]
		.filter((section) => section.showField)
		.sort((a, b) => a.nr - b.nr);
	const filteredFieldMapping = fieldMapping?.filter((item) => !item?.hideWeb);
	const orphanFields = _.filter(filteredFieldMapping, (item) => item?.item?.parentId === "");
	const groupedFieldsBySortedSections = sortedSectionsWithShowField.reduce((acc, section) => {
		acc[section.id] = _.filter(
			filteredFieldMapping.filter((item) => !item?.item?.hideWeb),
			(item) => item?.item?.parentId === section.id,
		);
		return acc;
	}, {});
	const groupedFields = {
		orphanFields,
		...groupedFieldsBySortedSections,
	};
	const [showFileModal, setShowFileModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(false);
	const handlePreviewFile = (file) => {
		setShowFileModal(true);
		setSelectedFile(file);
	};

	const label = {
		en: "labelEn",
		de: "labelDe",
	};
	const parseValue = (value) => {
		try {
			return JSON.parse(value);
		} catch {
			return [value];
		}
	};
	const getDisplayValue = (element) => {
		switch (element.inputType) {
			case "input":
			case "title":
			case "radioGroup":
			case "date":
			case "email":
				const inputValue = _.get(order, element.name) || "-";
				return typeof inputValue === "number" && element.inputType === "date"
					? moment(inputValue).format("DD/MM/YYYY")
					: inputValue;
			case "datePicker":
				return moment(getValue(_.get(order, element.name))).format("DD.MM.YYYY") || "-";
			case "asyncDropDown":
				const asyncValue = getValue(_.get(order, element.name));
				if (element.multiple) {
					if (Array.isArray(asyncValue) && asyncValue.length > 0) {
						return asyncValue.map((item) => item.name);
					}
					return ["-"];
				}
				return asyncValue?.name || "-";

			case "customDropdown":
				const value = _.get(order, element?.name)?.value
					? _.get(order, element?.name)?.value
					: _.get(order, element?.name);
				if (value) {
					const isGeneric =
						element?.item?.fixedNoteEn === "generic" ||
						element?.item?.name === "previousclient_ENG" ||
						element?.item?.requestType;
					if (isGeneric) {
						return value;
					}
					if (element?.item?.isMultiple) {
						const finalValue = [];
						const parsedValue = parseValue(value);
						for (let i = 0; i < parsedValue?.length; i++) {
							const currentValue = parsedValue[i];
							const foundedInput = filteredFieldMapping?.find(
								(item) => item?.item?.name === currentValue,
							);
							finalValue.push(foundedInput?.item?.[label[currentLanguage]]);
						}
						return finalValue?.join(",");
					}
					const foundedInput = filteredFieldMapping?.find(
						(item) => item?.item?.name === value,
					);
					return foundedInput?.item?.[label[currentLanguage]] || value;
				}
				return "-";

			case "image":
				return _.get(order, element.name) || "-";

			case "signature":
				const imageUrl = _.get(order, element.name)?.changed
					? _.get(order, element.name).value
					: _.get(order, element.name);
				return imageUrl ? (
					<div className="signatureImage">
						<img src={imageUrl} alt="" />
					</div>
				) : null;
			case "upload":
				const urls = _.get(order, element.name)?.changed
					? _.get(order, element.name).value
					: _.get(order, element.name);
				const files = urls ? JSON.parse(urls) : [];
				const filesArray = generateFilesArray(files);
				return (
					<div className="uploads__wrapper">
						{filesArray?.map((file) => (
							<MediaBox
								item={file}
								key={file.filename}
								handleOpen={handlePreviewFile}
								myMessage={false}
								type={_.get(order, element.name)?.changed ? "changed" : "default"}
							/>
						))}
					</div>
				);
			case "checkbox":
				const currentValue = _.get(order, element.name);
				const checkboxValue = isCustomFieldChecked(currentValue);
				return t(`${checkboxValue ? "" : "un"}checked`);
			default:
				return "";
		}
	};
	const getValue = (displayValue) => {
		if (displayValue?.changed) {
			return displayValue?.value !== "" ? displayValue?.value : "-";
		}
		return displayValue;
	};
	return (
		<div className="order_modals">
			<PreviewFile
				setPreviewPdf={setShowFileModal}
				showModal={showFileModal}
				previewFile={selectedFile}
				canDownload={true}
				downloadFn={() => handleDownloadNoToken(selectedFile)}
			/>
			<div className="previewOrder__container">
				{loading ? (
					<Loader />
				) : (
					Object.keys(groupedFields).map((sectionId) => {
						const arrayGroup = groupedFields[sectionId];
						return (
							<React.Fragment key={sectionId}>
								<Title
									title={
										_.find(sections, {
											id: Number(sectionId),
										})?.[sectionName]
									}
								/>
								<div className="previewOrder__container-sectionWrapper">
									<div className="previewOrder__container-sections">
										{arrayGroup
											?.filter((itm) => itm.inputType !== "boolean")
											.map((element, index) => {
												const displayValue = getDisplayValue(element);
												return (
													<div
														className="previewOrder__container-sectionColumn"
														key={index}
													>
														<div className="previewOrder__container-sections-keyValue">
															<HtmlTooltip
																title={
																	<span>
																		{t(element.placeholder)}
																	</span>
																}
																border="1px solid #e8e8e8"
																paddingLeft="10px"
																paddingRight="10px"
																paddingTop="5px"
																paddingBottom="5px"
																placement="top"
																disableHoverListener={false}
																fontWeight="600"
																color="#ffff"
																fontSize="12px"
																backgroundColor="#C1C1C1"
															>
																<div
																	style={{
																		color:
																			(displayValue?.changed ||
																				_.get(
																					order,
																					element?.name,
																				)?.changed) &&
																			"var(--color-primary)",
																	}}
																	className="previewOrder__container-sections-keyValue-key"
																>
																	{element.placeholder
																		? `${t(
																				element.placeholder,
																		  )}:`
																		: ""}
																</div>
															</HtmlTooltip>
															<div className="previewOrder__container-sections-keyValue-value">
																{element.multiple &&
																Array.isArray(
																	getValue(displayValue),
																) &&
																getValue(displayValue).length >
																	0 ? (
																	<>
																		<HtmlTooltip
																			title={
																				<div
																					style={{
																						display:
																							"flex",
																						flexDirection:
																							"column",
																						gap: "5px",
																					}}
																				>
																					{getValue(
																						displayValue,
																					).map(
																						(
																							value,
																							index,
																						) => (
																							<span
																								key={
																									index
																								}
																								style={{
																									display:
																										"flex",
																									gap: "5px",
																									color:
																										order[
																											element
																												?.name
																										]
																											?.changed ||
																										(displayValue?.changed &&
																											"var(--color-primary)"),
																								}}
																							>
																								{index +
																									1}
																								.
																								{
																									value
																								}
																							</span>
																						),
																					)}
																				</div>
																			}
																			placement="top-start"
																			disableHoverListener={
																				false
																			}
																			fontWeight="600"
																			color="#979797"
																			fontSize="12px"
																			backgroundColor="#e5e5e5"
																			border="1px solid #D9D9D9"
																			borderRadius="8px"
																			boxShadow="1px 2px 6px 0px #0000001A"
																			maxWidth="200px"
																		>
																			<span
																				style={{
																					color:
																						order[
																							element
																								?.name
																						]
																							?.changed &&
																						"var(--color-primary)",
																				}}
																			>
																				{getValue(
																					displayValue,
																				).join(", ")}
																			</span>
																		</HtmlTooltip>
																	</>
																) : (
																	<span
																		style={{
																			color:
																				(displayValue?.changed ||
																					_.get(
																						order,
																						element?.name,
																					)?.changed) &&
																				"var(--color-primary)",
																		}}
																	>
																		{getValue(displayValue)}
																	</span>
																)}
															</div>
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</React.Fragment>
						);
					})
				)}
				{order?.emailLogs?.[0] && (
					<div className="order__activity">
						<p className="order__activity--title">{t("activity")}</p>
						<div className="order__activity--container">
							{order?.emailLogs?.map((item) => (
								<ActivityBox
									email={item?.email}
									sendAt={item?.sendAt}
									sender={order.salesPartnerName}
									key={item.id}
									Icon={EmailIcon}
									title={
										item.type === "OFFER"
											? t("OfferEmailSentTo")
											: t("EmailSentTo")
									}
								/>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	editedOrderFields: state.app.client.index.editedOrderFields,
	sections: state.app.client.index.sections,
	editedSections: state.app.client.index.sections,
	loading: state.app.orders.edit.loading,
});

const mapDispatchToProps = {
	editOrder: editOrderActions.editOrder,
	fillOrder: editOrderActions.fillOrder,
	fetchGenericOrderByID: clientActions.fetchGenericOrderByID,
	removeFile: editOrderActions.removeOrderFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreviewOrder));
