import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as structuresActions} from "@sagas/structures/index";
import {actions as createStructuresActions} from "@sagas/structures/create";
import {actions as deleteStructuresActions} from "@sagas/structures/delete";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {DeleteRowIcon, EditRowIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import HtmlTooltip from "@src/common/htmlTooltip";
import Loader from "@src/common/Loader";
import Form from "@src/common/Form";
import {useUrlParams} from "@src/utils/useUrlParams";
import {ENDPOINT} from "@src/utils/endpoint";
import {structureTableColumns, structureTableInputs, stufeInputs} from "./helper";
import ConditionAccordion from "../Users/TeamConditions/Accordion/Accordion";
import StructureTable from "./components/Table";

const Structures = (props) => {
	const {
		structuresState,
		deleteStructure,
		getStructures,
		getStufes,
		createLoader,
		createStufe,
		createStructure,
		deleteStufe,
		deleteLoader,
		editPage,
	} = props;
	const {setModal, getUrlParams, setUrlParams} = useUrlParams();
	const {modal, id, expandedRow, careerConditions} = getUrlParams(
		"modal",
		"id",
		"expanded-row",
		"careerConditions",
	);
	const setExpandedRow = (id) => setUrlParams(id === expandedRow ? "none" : id, "expanded-row");
	const {t} = useTranslation();
	const {structures, loading, stufes, stufeLoading, paginationData} = structuresState;
	const {page, totalPages} = paginationData;
	const modalTypeActions = {
		delete: (id, item) => handleConfirmModal(id, "deleteStructure", item),
		edit: (id) => setModal("structure", id),
	};
	const handleModal = (type, id, item) => {
		const fn = modalTypeActions[type];
		fn(id, item);
	};
	const handleConfirmModal = (id, type, item) => {
		const {careerConditionIds} = item;
		const extraParam =
			careerConditionIds?.length > 0 ? [{careerConditions: careerConditionIds}] : [];
		setModal(type, id, extraParam);
	};
	const closeConfirmModal = () => setModal(false);
	const handleConfirm = () => {
		if (modal === "deleteStructure") {
			const conditionIds = [];
			if (typeof careerConditions === "number" && careerConditions !== 0) {
				conditionIds.push(careerConditions);
			} else {
				const ids = careerConditions.split(",").map(Number);
				conditionIds.push(...ids);
			}
			deleteStructure({id, setModal, conditionIds});
		} else {
			deleteStufe({id, setModal});
		}
	};
	useEffect(() => {
		getStructures();
	}, [page]);
	useEffect(
		() => () => {
			editPage(1);
		},
		[],
	);

	useEffect(() => {
		if (expandedRow && expandedRow !== "none") getStufes(expandedRow);
	}, [expandedRow]);
	const columnsWithActions = [
		...structureTableColumns,
		{
			key: "",
			width: 50,
			resizable: false,
			formatter: ({row}) => (
				<div
					className="table-actions-wrapper"
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<HtmlTooltip title={<span>{t("EditRow")}</span>} placement="bottom-end">
						<span onClick={() => setModal("stufe", row?.id)}>
							<EditRowIcon />
						</span>
					</HtmlTooltip>
					<HtmlTooltip title={<span>{t("DeleteRow")}</span>} placement="bottom-end">
						<span onClick={() => handleConfirmModal(row?.id, "deleteStufe", row)}>
							<DeleteRowIcon />
						</span>
					</HtmlTooltip>
				</div>
			),
		},
	];
	const handleScroll = ({currentTarget}) => {
		const {scrollTop} = currentTarget;
		const {scrollHeight} = currentTarget;
		const {clientHeight} = currentTarget;
		const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;

		if (isAtBottom && page < totalPages) {
			editPage(page + 1);
		}
	};
	const returnTitle = () => {
		if (modal === "structure") {
			return id ? `${t("EditStructure")}` : `${t("CreateStructure")}`;
		}
		return id ? `${t("EditStufe")}` : `${t("CreateStufe")}`;
	};
	const formData = {
		inputs: modal === "structure" ? structureTableInputs : stufeInputs(id),
		submitFn: (values) => {
			if (modal === "structure") {
				createStructure({values, setModal});
			} else {
				createStufe({values: {...values, structureId: expandedRow}, setModal});
			}
		},
		id,
		title: returnTitle(),
		getUrl: modal === "structure" ? ENDPOINT.GET_STRUCTURE_BY_ID : ENDPOINT.GET_STUFE_BY_ID,
	};

	return (
		<>
			<DeleteModal
				open={modal === "deleteStructure" || modal === "deleteStufe"}
				handleClose={closeConfirmModal}
				loading={deleteLoader}
				disabled={deleteLoader}
				setShowDeleteModal={closeConfirmModal}
				confirm={handleConfirm}
				calculationModal={!!careerConditions}
				newTitle={t("deleteStructureInfo")}
			/>
			<Form
				open={modal === "structure" || modal === "stufe"}
				formData={formData}
				handleClose={() => setModal(false)}
				loading={createLoader}
			/>
			<div className="structures__container" onScroll={handleScroll}>
				{structures.map((item) => (
					<ConditionAccordion
						key={item.id}
						title={item.name}
						item={item}
						isExpired={false}
						showSaveIcon={false}
						id={item.id}
						show
						deletable
						onChange={() => setExpandedRow(item.id)}
						expanded={expandedRow === item?.id}
						handleModal={handleModal}
						hasDates={false}
					>
						{stufeLoading && expandedRow === item?.id ? (
							<div className="custom_stufe__loader">
								<Loader />
							</div>
						) : (
							<StructureTable
								columns={columnsWithActions}
								rows={stufes}
								structureId={item.id}
								setModal={setModal}
							/>
						)}
					</ConditionAccordion>
				))}
				{loading && (
					<div className={`structures__container-loader ${page > 1 && "bottom"}`}>
						<Loader />
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	structuresState: state.app.structures.index,
	createLoader: state.app.structures.create.loading,
	deleteLoader: state.app.structures.delete.loading,
});

const mapDispatchToProps = {
	deleteStructure: deleteStructuresActions.deleteStructureTable,
	deleteStufe: deleteStructuresActions.deleteStufe,
	getStructures: structuresActions.getStructures,
	getStufes: structuresActions.getStufesByStructureId,
	createStufe: createStructuresActions.createStufe,
	createStructure: createStructuresActions.createStructureTable,
	editPage: structuresActions.editPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Structures));
