/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import {ToastErrorComponent, ToastSuccesComponent} from "@common/ToastComponent/ToastComponent";
import {
	customerTypeData,
	personTitleData,
} from "@components/dashboard/orders/createEditOrder/forms";
import axios from "@utils/axios";
import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import _ from "lodash";
import moment from "moment";
import {actions as orderActions} from "./index";
import i18n from "../../../../i18n";
import {updateCustomFieldAttachments} from "./utils";
const logger = new Logger("Orders Edit");

const PREFIX = "@app/orders/edit";
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_ORDER = `${PREFIX}GET_ORDER`;
export const EDIT_ORDER = `${PREFIX}EDIT_ORDER`;
export const FILL_ORDER = `${PREFIX}FILL_ORDER`;
export const TOGGLE_MODAL = `${PREFIX}TOGGLE_MODAL`;
export const UPLOAD_ORDER_FILE = `${PREFIX}UPLOAD_ORDER_FILE`;
export const UPLOAD_ORDER_FILE_SUCCESS = `${PREFIX}UPLOAD_ORDER_FILE_SUCCESS`;
export const REMOVE_ORDER_FILE = `${PREFIX}REMOVE_ORDER_FILE`;
export const REMOVE_ORDER_FILE_SUCCESS = `${PREFIX}REMOVE_ORDER_FILE_SUCCESS`;
export const SET_SHOW_TIMELINE = `${PREFIX}SET_SHOW_TIMELINE`;
export const GET_ACTIVITIES = `${PREFIX}GET_ACTIVITIES`;
export const GET_ACTIVITIES_SUCCESS = `${PREFIX}GET_ACTIVITIES_SUCCESS`;
export const GET_ACTIVITIES_BYID = `${PREFIX}GET_ACTIVITIES_BYID`;
export const GET_ACTIVITIES_BYID_SUCCESS = `${PREFIX}GET_ACTIVITIES_BYID_SUCCESS`;
export const SET_ACTIVITIES_LOADING = `${PREFIX}SET_ACTIVITIES_LOADING`;
export const SET_ORDER_WITH_CHANGES = `${PREFIX}SET_ORDER_WITH_CHANGES`;
export const SET_ORDER_STATUS_IN_PROGRESS = `${PREFIX}SET_ORDER_STATUS_IN_PROGRESS`;

const FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];
const FILE_SIZE = 2 * 1024 * 1024;

const _state = {
	showModal: false,
	loading: false,
	order: null,
	showTimeline: false,
	activities: [],
	activity: null,
	activitiesLoading: false,
	orderWithChanges: null,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case FILL_ORDER:
				draft.order = action.payload;
				break;
			case TOGGLE_MODAL:
				draft.showModal = action.payload ?? false;
				draft.showTimeline = false;
				draft.activities = [];
				draft.activity = null;
				break;
			case REMOVE_ORDER_FILE_SUCCESS:
				const object = updateCustomFieldAttachments(
					state.order?.customFieldsValue,
					action?.payload?.name,
					action?.payload?.file,
					"remove",
				);
				draft.order = {
					...state.order,
					customFieldsValue: object,
				};
				break;
			case UPLOAD_ORDER_FILE_SUCCESS:
				const customFieldObject = updateCustomFieldAttachments(
					state.order?.customFieldsValue,
					action?.payload?.name,
					action?.payload?.file,
					"add",
					action?.payload?.fieldMapping,
				);
				draft.order = {
					...state.order,
					customFieldsValue: customFieldObject,
				};
				break;
			case SET_SHOW_TIMELINE:
				if (!action.payload) {
					draft.activity = null;
				}
				draft.showTimeline = action.payload;
				break;
			case GET_ACTIVITIES_SUCCESS:
				const groupedByMonth = _.groupBy(action.payload, (item) => {
					const date = moment(item.createdAt);
					return date.format("MMMM");
				});
				const sortedMonths = _.sortBy(Object.keys(groupedByMonth), (month) =>
					moment(month, "MMMM YYYY").toDate().getTime(),
				).reverse();
				const sortedGroupedByMonth = {};
				sortedMonths.forEach((month) => {
					sortedGroupedByMonth[month] = groupedByMonth[month];
				});
				draft.activities = sortedGroupedByMonth;
				break;
			case GET_ACTIVITIES_BYID_SUCCESS:
				if (action.payload?.prevStatus) {
					draft.activity = {...state.order, ...action.payload};
				} else {
					draft.activity = action.payload;
				}
				break;
			case SET_ACTIVITIES_LOADING:
				draft.activitiesLoading = action.payload;
				break;
			case SET_ORDER_WITH_CHANGES:
				const createChangeObject = (obj1, obj2) =>
					_.mapValues(obj2, (value, key) => {
						if (_.isObject(value) && !_.isArray(value) && !_.isNull(value)) {
							return createChangeObject(obj1[key] || {}, value);
						}
						if (!_.isEqual(value, obj1[key])) {
							return {value, changed: true};
						}
						return obj1[key];
					});
				const reverseChangeObject = (changeObj) =>
					_.mapValues(changeObj, (value) => {
						if (_.isObject(value) && !_.isArray(value) && !_.isNull(value)) {
							if (_.has(value, "changed")) {
								return value.value;
							}
							return reverseChangeObject(value);
						}
						return value;
					});
				draft.activity = action.payload
					? createChangeObject(state.order, state.activity)
					: reverseChangeObject(state?.activity);
				break;
			case SET_ORDER_STATUS_IN_PROGRESS:
				if (state?.order?.orderId) {
					for (let i = 0; i < action.payload.length; i++) {
						const order = action.payload[i];
						if (order.id === state?.order.orderId) {
							draft.order = {
								...state.order,
								status: i18n.t("inProgress"),
							};
						}
					}
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getOrder: (payload) => createAction(GET_ORDER, {payload}),
	editOrder: (payload) => createAction(EDIT_ORDER, {payload}),
	fillOrder: (payload) => createAction(FILL_ORDER, {payload}),
	toggleModal: (payload) => createAction(TOGGLE_MODAL, {payload}),
	uploadOrderFile: (payload) => createAction(UPLOAD_ORDER_FILE, {payload}),
	uploadOrderFileSuccess: (payload) => createAction(UPLOAD_ORDER_FILE_SUCCESS, {payload}),
	removeOrderFile: (payload) => createAction(REMOVE_ORDER_FILE, {payload}),
	removeOrderFileSuccess: (payload) => createAction(REMOVE_ORDER_FILE_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowTimeline: (payload) => createAction(SET_SHOW_TIMELINE, {payload}),
	getActivities: (payload) => createAction(GET_ACTIVITIES, {payload}),
	getActivitiesSuccess: (payload) => createAction(GET_ACTIVITIES_SUCCESS, {payload}),
	getActivitiesById: (payload) => createAction(GET_ACTIVITIES_BYID, {payload}),
	getActivitiesByIdSuccess: (payload) => createAction(GET_ACTIVITIES_BYID_SUCCESS, {payload}),
	setActivitiesLoading: (payload) => createAction(SET_ACTIVITIES_LOADING, {payload}),
	setOrdersWithChanges: (payload) => createAction(SET_ORDER_WITH_CHANGES, {payload}),
	setOrderStatusInProgress: (payload) => createAction(SET_ORDER_STATUS_IN_PROGRESS, {payload}),
};
export const getSelectOptionsFromData = (data, label, value, secondLabel) => {
	let arr = [];
	if (data?.length > 0) {
		arr = data?.map((item) => ({
			name: secondLabel ? `${item[label]} ${item[secondLabel || ""]}` : item[label],
			value: item[value],
		}));
	}
	return arr;
};

export const sagas = {
	*editOrder({payload}) {
		const {values, actions: formActions} = payload;
		formActions.setSubmitting(true);
		try {
			const object = {
				status: values?.status,
				keepPhoneNumber: values.keepPhoneNumber,
				contractImage: values.contractImage,
				viaET: values.viaET,
				viaMFH: values.viaMFH,
				newNumber: values.newNumber,
				secondVoiceChannel: values.secondVoiceChannel,
				itemizedBillNone: values.itemizedBillNone,
				itemizedBillShortened: values.itemizedBillShortened,
				itemizedBillCompletely: values.itemizedBillCompletely,
				tariffId: values.tariffId,
				projectCategoryId: values?.projectCategoryId,
				locationId: values.locationId,
				products: values.productIds,
				clientId: values.clientId?.value,
				costumerType: values.costumerType?.value,
				emailNewsLetter: values.emailNewsLetter,
				termsAndConditions: values.termsAndConditions,
				sendNewsLetter: values.sendNewsLetter,
				apartmentHabitations: values.apartmentHabitations,
				updateOrderId: values.updateOrderId,
				floor: values.floor,
				signature: values.signature,
				hvSignature: values?.hvSignature,
				homeOwner: values.homeOwner === "homeOwner",
				ownerAssociation: values.homeOwner === "ownerAssociation",
				checkFormerProvider: values.checkFormerProvider === "true",
				entryInThePhone: true,
				oneFamilyHouse: values.createOrderDetails.homeOwner === "oneFamilyHouse",
				apartmentBuilding: values.createOrderDetails.homeOwner === "apartmentBuilding",
				underground: values.createOrderDetails.apartmentFloor === "underground",
				groundFloor: values.createOrderDetails.apartmentFloor === "groundFloor",
				upstairs: values.createOrderDetails.apartmentFloor === "upstairs",
				orderId: values.orderId,
				userTitle: values.userTitle,
				company: values.createOrderDetails.company || values.company,
				orderProductNumber: values.orderProductNumber,
				createOrderDetails: {
					...values.createOrderDetails,
					salutation: values?.createOrderDetails?.salutation?.value,
				},
				createFormerProvider: {
					...values?.createFormerProvider,
					salutation: values?.createFormerProvider?.salutation?.value,
				},
				providerChange: {
					...values.providerChange,
					salutation: values?.providerChange?.salutation?.value,
				},
				additionalContractor: {
					...values.additionalContractor,
					salutation: values?.additionalContractor?.salutation?.value,
				},
				onThePhone: values.onThePhone,
				createBankAccount: {
					...values.createBankAccount,
					salutation: values?.createBankAccount?.salutation?.value,
				},
				createDataPolicy: {
					...values.createDataPolicy,
				},
				createOwnerInfo: {
					...values.createOwnerInfo,
				},
				createAddressForInvoice: {
					...values.createAddressForInvoice,
					salutation: values?.createAddressForInvoice?.salutation?.value,
				},
				customFieldValue: {
					...values?.customFieldsValue,
				},
				clientContractType: values.clientContractType,
				clientPayoutModel: values.clientPayoutModel,
			};
			const endpoint =
				object?.clientContractType === "ENERGY"
					? "/v1/energy/order/update"
					: "/order/update";
			yield axios.put(endpoint, object);
			yield put(actions.toggleModal());
			yield put(
				orderActions.editOrderOnState({
					orderId: values?.orderId,
					tariffName: values?.tariffName,
				}),
			);
			ToastSuccesComponent(i18n.t("OrderEditSuccess"));
		} catch (e) {
			let message = i18n.t("OrderEditError");
			if (e?.response.status === 409) {
				message = e?.response.data.message;
			} else if (e?.response?.data?.message?.[0]?.name === "DELETED_USER") {
				message = `${values?.salesPartnerName} ${e?.response?.data?.message?.[0]?.message}`;
			}
			ToastErrorComponent(message);
			logger.error(e);
		} finally {
			formActions.setSubmitting(false);
		}
	},
	*getOrder({payload}) {
		try {
			const object = {
				keepPhoneNumber: payload.keepPhoneNumber,
				contractImage: payload.contractImage,
				viaET: payload.viaET,
				viaMFH: payload.viaMFH,
				viaPapier: payload.viaPapier,
				contractImageView: getContractImageValue(payload),
				newNumber: payload.newNumber,
				secondVoiceChannel: payload.secondVoiceChannel,
				itemizedBillNone: payload.itemizedBillNone,
				itemizedBillShortened: payload.itemizedBillShortened,
				itemizedBillCompletely: payload.itemizedBillCompletely,
				tariffId: {name: payload.tariffName, value: payload.tariffId},
				updateOrderId: payload.orderId,
				projectCategoryId: {
					name: payload.projectCategoryName,
					value: payload.projectCategoryId,
				},
				locationId: {name: payload.locationName, value: payload.locationId},
				clientId: {value: payload.clientId},
				clientName: payload.clientName,
				signature: payload.signature,
				hvSignature: payload.hvSignature,
				teamId: payload.teamId,
				userTitle: payload.orderDetailsData.userTitle,
				company: payload.orderDetailsData.company,
				contractFile: payload.contractFile,
				vpID: payload.vpID,
				products: getSelectOptionsFromData(payload.addons, "productName", "productId"),
				contractId: payload.contractId,
				orderProductNumber: payload.orderProductNumber,
				homeOwner: getHomeOwnerValueHNT(payload),
				ownerAssociation: payload.ownerAssociation,
				termsAndConditions: payload.termsAnConditions,
				checkFormerProvider: String(payload.formerProviderCheck),
				entryInThePhone: payload.entryInThePhone,
				sendNewsLetter: payload.sendNewsLetter,
				emailNewsLetter: payload.emailNewsLetter,
				oneFamilyHouse: payload.oneFamilyHouse,
				apartmentBuilding: payload.apartmentBuilding,
				apartmentHabitations: payload.apartmentHabitations,
				underground: payload.underground,
				floor: payload.floor,
				groundFloor: payload.groundFloor,
				upstairs: payload.upstairs,
				left: payload.left,
				right: payload.right,
				middle: payload.middle,
				orderId: payload.orderId,
				costumerType: customerTypeData.find((e) => e.value === payload?.costumerType),
				additionalContractor: {
					...payload.additionalContractor,
					salutation: personTitleData.find(
						(e) => e.value === payload?.additionalContractor?.salutation,
					),
				},
				createOrderDetails: {
					...payload.viewOrderDetails,
					apartmentFloor: getApartmentFloorValue(payload),
					homeOwner: getHomeOwnerValue(payload),
					userEmail: payload.orderDetailsData.userEmail,
					company: payload.orderDetailsData.company,
					salutation: personTitleData.find(
						(e) => e.value === payload?.viewOrderDetails?.salutation,
					),
				},
				customFieldsValue: {
					...payload?.customFieldsValue,
				},
				customFieldsUploadValue: {
					...payload?.customFieldsUploadValue,
				},
				createFormerProvider: {
					...payload.viewFormerProvider,
					salutation: personTitleData.find(
						(e) => e.value === payload?.viewFormerProvider?.salutation,
					),
				},
				providerChange: {
					...payload.viewProviderChange,
					salutation: personTitleData.find(
						(e) => e.value === payload?.viewProviderChange?.salutation,
					),
				},
				createBankAccount: {
					...payload.viewBankAccount,
					salutation: personTitleData.find(
						(e) => e.value === payload?.viewBankAccount?.salutation,
					),
				},
				createDataPolicy: {
					...payload.viewDataPolicy,
					// salutation: personTitleData.find(
					// 	(e) => e.value === payload?.viewBankAccount?.salutation,
					// ),
				},
				createOwnerInfo: {
					...payload.viewOwnerInfo,
				},
				createAddressForInvoice: {
					...payload.viewAddressForInvoice,
					salutation: personTitleData.find(
						(e) => e.value === payload?.viewAddressForInvoice?.salutation,
					),
				},
				onThePhone: payload.onThePhone,
				importData: {
					fileId: payload?.importedFileId,
					contractId: payload?.importedContractId,
					dateOfContract: payload?.importedDateOfContract,
					orderStatus: payload?.importedOrderStatus,
					vpNumber: payload?.importedVpNumber,
					vpName: payload?.importedVpName,
				},
				isScheduleDeleted: payload.isScheduleDeleted,
				emailLogs: payload?.emailLogs,
				salesPartnerName: payload?.salesPartnerName,
				clientOrderId: payload?.clientOrderId,
				status: payload?.status,
				clientContractType: payload.clientContractType,
				clientPayoutModel: payload.clientPayoutModel,
			};
			yield put(actions.fillOrder(object));
		} catch (e) {
			logger.error(e);
		}
	},
	*removeOrderFile({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.post(`/order/delete/file`, {
				file: payload?.file,
			});
			yield put(actions.removeOrderFileSuccess(payload));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*uploadOrderFile({payload}) {
		yield put(actions.setLoading(true));
		try {
			for (let index = 0; index < payload.length; index++) {
				const element = payload[index];
				const {size, filename, type, base64, name, values, fieldMapping} = element;
				if (FILE_TYPES.includes(type) && size <= FILE_SIZE) {
					const response = yield axios.post(`/order/file/upload`, {
						file: base64,
						fileName: filename,
					});
					yield put(
						actions.uploadOrderFileSuccess({
							file: response?.data?.data,
							name,
							fieldMapping,
							values,
						}),
					);
				} else if (!FILE_TYPES.includes(type)) {
					ToastErrorComponent(i18n.t("FileTypeError"));
				} else if (size > FILE_SIZE) {
					ToastErrorComponent(i18n.t("FileSizeError2MB"));
				}
			}
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getActivities({payload}) {
		yield put(actions.setActivitiesLoading(true));
		try {
			const {showTimeline} = yield select((state) => state.app.orders.edit);
			yield put(actions.setShowTimeline(!showTimeline));
			if (!showTimeline) {
				const response = yield axios.get(`/activity/all/${payload}`);
				yield put(actions.getActivitiesSuccess(response?.data?.data));
			}
			yield put(actions.setActivitiesLoading(false));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setActivitiesLoading(false));
		}
	},
	*getActivitiesById({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/activity/${payload}`);
			const parsedResponse = JSON.parse(response.data.data.changes);
			if (parsedResponse?.changeStatus) {
				const object = {
					status: parsedResponse.status,
					prevStatus: parsedResponse.prevStatus,
				};
				yield put(actions.getActivitiesByIdSuccess(object));
			} else {
				const object = {
					keepPhoneNumber: parsedResponse?.keepPhoneNumber,
					contractImage: parsedResponse?.contractImage,
					viaET: parsedResponse?.viaET,
					viaMFH: parsedResponse?.viaMFH,
					viaPapier: parsedResponse?.viaPapier,
					contractImageView: getContractImageValue(parsedResponse),
					newNumber: parsedResponse?.newNumber,
					secondVoiceChannel: parsedResponse?.secondVoiceChannel,
					itemizedBillNone: parsedResponse?.itemizedBillNone,
					itemizedBillShortened: parsedResponse?.itemizedBillShortened,
					itemizedBillCompletely: parsedResponse?.itemizedBillCompletely,
					tariffId: {name: parsedResponse?.tariffName, value: parsedResponse?.tariffId},
					updateOrderId: parsedResponse?.orderId,
					projectCategoryId: {
						name: parsedResponse?.projectCategoryName,
						value: parsedResponse?.projectCategoryId,
					},
					locationId: {
						name: parsedResponse?.locationName,
						value: parsedResponse?.locationId,
					},
					clientId: {value: parsedResponse?.clientId},
					clientName: parsedResponse?.clientName,
					signature: parsedResponse?.signature,
					hvSignature: parsedResponse?.hvSignature,
					teamId: parsedResponse?.teamId,
					userTitle: parsedResponse?.orderDetailsData?.userTitle,
					company: parsedResponse?.orderDetailsData?.company,
					contractFile: parsedResponse?.contractFile,
					vpID: parsedResponse?.vpID,
					products: getSelectOptionsFromData(
						parsedResponse?.addons,
						"productName",
						"productId",
					),
					contractId: parsedResponse?.contractId,
					orderProductNumber: parsedResponse?.orderProductNumber,
					homeOwner: getHomeOwnerValueHNT(parsedResponse),
					ownerAssociation: parsedResponse?.ownerAssociation,
					termsAndConditions: parsedResponse?.termsAnConditions,
					checkFormerProvider: String(parsedResponse?.formerProviderCheck),
					entryInThePhone: parsedResponse?.entryInThePhone,
					sendNewsLetter: parsedResponse?.sendNewsLetter,
					emailNewsLetter: parsedResponse?.emailNewsLetter,
					oneFamilyHouse: parsedResponse?.oneFamilyHouse,
					apartmentBuilding: parsedResponse?.apartmentBuilding,
					apartmentHabitations: parsedResponse?.apartmentHabitations,
					underground: parsedResponse?.underground,
					floor: parsedResponse?.floor,
					groundFloor: parsedResponse?.groundFloor,
					upstairs: parsedResponse?.upstairs,
					left: parsedResponse?.left,
					right: parsedResponse?.right,
					middle: parsedResponse?.middle,
					orderId: parsedResponse?.orderId,
					costumerType: customerTypeData.find(
						(e) => e.value === parsedResponse?.costumerType,
					),
					additionalContractor: {
						...parsedResponse?.additionalContractor,
						salutation: personTitleData.find(
							(e) => e.value === parsedResponse?.additionalContractor?.salutation,
						),
					},
					createOrderDetails: {
						...parsedResponse?.viewOrderDetails,
						apartmentFloor: getApartmentFloorValue(parsedResponse),
						homeOwner: getHomeOwnerValue(parsedResponse),
						userEmail: parsedResponse?.orderDetailsData?.userEmail,
						salutation: personTitleData.find(
							(e) => e.value === parsedResponse?.viewOrderDetails?.salutation,
						),
					},
					customFieldsValue: {
						...parsedResponse?.customFieldsValue,
					},
					customFieldsUploadValue: {
						...parsedResponse?.customFieldsUploadValue,
					},
					createFormerProvider: {
						...parsedResponse?.viewFormerProvider,
						salutation: personTitleData.find(
							(e) => e.value === parsedResponse?.viewFormerProvider?.salutation,
						),
					},
					providerChange: {
						...parsedResponse?.viewProviderChange,
						salutation: personTitleData.find(
							(e) => e.value === parsedResponse?.viewProviderChange?.salutation,
						),
					},
					createBankAccount: {
						...parsedResponse?.viewBankAccount,
						salutation: personTitleData.find(
							(e) => e.value === parsedResponse?.viewBankAccount?.salutation,
						),
					},
					createDataPolicy: {
						...parsedResponse?.viewDataPolicy,
						// salutation: personTitleData.find(
						// 	(e) => e.value === parsedResponse?.viewBankAccount?.salutation,
						// ),
					},
					createOwnerInfo: {
						...parsedResponse?.viewOwnerInfo,
					},
					createAddressForInvoice: {
						...parsedResponse?.viewAddressForInvoice,
						salutation: personTitleData.find(
							(e) => e.value === parsedResponse?.viewAddressForInvoice?.salutation,
						),
					},
					onThePhone: parsedResponse?.onThePhone,
					importData: {
						fileId: parsedResponse?.importedFileId,
						contractId: parsedResponse?.importedContractId,
						dateOfContract: parsedResponse?.importedDateOfContract,
						orderStatus: parsedResponse?.importedOrderStatus,
						vpNumber: parsedResponse?.importedVpNumber,
						vpName: parsedResponse?.importedVpName,
					},
					isScheduleDeleted: parsedResponse?.isScheduleDeleted,
					emailLogs: parsedResponse?.emailLogs,
					salesPartnerName: parsedResponse?.salesPartnerName,
					clientOrderId: parsedResponse?.clientOrderId,
					status: parsedResponse?.status,
				};

				yield put(actions.getActivitiesByIdSuccess(object));
			}
			yield put(actions.setLoading(false));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(EDIT_ORDER, sagas.editOrder);
	yield takeLatest(GET_ORDER, sagas.getOrder);
	yield takeLatest(UPLOAD_ORDER_FILE, sagas.uploadOrderFile);
	yield takeLatest(REMOVE_ORDER_FILE, sagas.removeOrderFile);
	yield takeLatest(GET_ACTIVITIES, sagas.getActivities);
	yield takeLatest(GET_ACTIVITIES_BYID, sagas.getActivitiesById);
};
const getApartmentFloorValue = (data) => {
	if (data?.underground) return "underground";
	if (data?.groundFloor) return "groundFloor";
	if (data?.upstairs) return "upstairs";
	return "";
};
const getHomeOwnerValue = (data) => {
	if (data?.oneFamilyHouse) return "oneFamilyHouse";
	if (data?.apartmentBuilding) return "apartmentBuilding";
	return "";
};
const getHomeOwnerValueHNT = (data) => {
	if (data?.homeOwner) return "homeOwner";
	if (data?.ownerAssociation) return "ownerAssociation";
	return "";
};
const getContractImageValue = (data) => {
	if (data?.viaET) return "viaET";
	if (data?.viaMFH) return "viaMFH";
	if (!data?.viaMFH && !data?.viaET) return "viaPapier";
	return "";
};
